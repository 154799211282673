const QuienesSomosData = [
    {id: 1,
    nombre: 'Clato',
    img: '/img/QuienesSomos/Clato.webp',
    texto: 'Creative solutions, market positioning and fruit juices 🍉'
    },
    {id: 2,
    nombre: 'Ludmila',
    img: '/img/QuienesSomos/Ludmila.webp',
    texto: 'Visual art and project manager. Lover puppies 🐶'
    },
    {id: 3,
    nombre: 'Octavio',
    img: '/img/QuienesSomos/Octavio.webp',
    texto: 'Web development, commercial intelligence and Boca Juniors ✋🏼😄🤚🏼'
    },
    {id: 4,
    nombre: 'Valentina',
    img: '/img/QuienesSomos/Valentina.webp',
    texto: 'Content creation, CM, potential voiceover artist and catlover 😻'
    },
   {id: 5,
    nombre: 'Lo',
    img: '/img/QuienesSomos/Lo.webp',
    texto: 'Communication, design and photography in process 📷'
    }, 
   {id: 6,
   nombre: 'Noelia',
   img: '/img/QuienesSomos/Noelia.webp',
   texto: 'Sales, interpersonal relationships and traveling ✈️'
    },         
]


export default QuienesSomosData;