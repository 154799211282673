import React from 'react';
import './nosotros.css';

function Nosotros() {
    return (
        <section className='nosotros'>
            <h2 className='titulo'>WHO IS <strong className='brand'>GALIDÉ?</strong></h2>
            <p className='texto'>In a distant galaxy, a <b>digital marketing agency</b> guides businesses toward achieving their goals in the vast digital environment. It offers comprehensive services in <b>advertising, design, SEO, and social media management</b> to elevate visibility and expand the reach of businesses across the stellar network. Additionally, it provides a <b>brand protection shield</b> to safeguard the identity and reputation of businesses, keeping them safe from potential threats in the ever-changing galactic digital landscape. May the Force of Digital Marketing be with you.</p>
        </section>
    );
}

export default Nosotros;