const servicios = [
    {id: 1,
    titulo: 'Brand Protection',
    img: '/img/Servicios/protect.webp',
    texto: 'Our comprehensive brand protection service guarantees the reputation, image and price control of our clients. We preserve the identity and prestige of a brand, developing solid strategies to face the challenges of the current market.',
    cel: 541143993437,
    msg: 'Hola, estoy en interesado en averiguar por los servicios de Protección de Marca de Galidé.'
    },
    {id: 2,
    titulo: 'Social Media',
    img: '/img/Servicios/mkt.webp',
    texto: 'We provide full social media management. Account administration, creation of innovative and engaging content to enhance your presence on social networks. Trust us to boost your brand and effectively reach your audience.',
    cel: 541132535790,
    msg: 'Hola, estoy en interesado en averiguar por los servicios de Redes Sociales de Galidé.'
    },
    {id: 3,
    titulo: 'SEO and WEB',
    img: '/img/Servicios/seo.webp',
    texto: 'Boost your online presence with SEO and SEM. We optimize your website with organic search strategies and manage effective advertising campaigns. Develop your website with modern, functional, and attractive technologies and programming languagues.',
    cel: 541143993437,
    msg: 'Hola, estoy en interesado en averiguar por los servicios de SEO-SEM y Desarrollo Web de Galidé.'
                },
    {id: 4,
    titulo: 'Brand Identity',
    img: '/img/Servicios/identidad.webp',
    texto: 'We build the DNA of your brand, from concept to visual identity. We consider your target audience and competition in its creation. Our development includes design archetypes, naming, logo, slogan, color palette, and much more.',
    cel: 541132535790,
    msg: 'Hola, estoy en interesado en averiguar por los servicios de Identidad de Marca de Galidé.'
        }
]


export default servicios;